import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";

export default class Api {

  static init() {
    axios.defaults.timeout = 50000 // 5 seconds
  }

  protected static postRequest<Q, R>(axios: AxiosInstance, url: string, data?: Q, config?: any, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    const conf: any = {};
    if (config) Object.assign(conf, config);
    return new Promise<R | null>((resolve) => {
      axios.post<R, AxiosResponse<R>, Q>(url, data, conf).then(async res => {
        await onSuc?.(res.data);
        resolve(res.data);
      }).catch(async (err: AxiosError<R, Q>) => {
        await onErr?.(err);
        resolve(null);
      });
    });
  }

  protected static getRequest<Q, R>(axios: AxiosInstance, url: string, data?: Q, config?: any, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    const conf: any = {};
    if (config) Object.assign(conf, config);
    if (data) conf.params = data;
    return new Promise<R | null>((resolve) => {
      axios.get<R, AxiosResponse<R>, Q>(url, conf).then(async res => {
        await onSuc?.(res.data);
        resolve(res.data);
      }).catch(async (err: AxiosError<R, Q>) => {
        await onErr?.(err);
        resolve(null);
      });
    });
  }

}
