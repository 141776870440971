export default class StorageValueChangeEvent {
  handlers: (() => void)[] = []

  addHandler(handler: () => void) {
    if(this.handlers.find(h => h === handler)) return
    this.handlers.push(handler)
  }

  removeHandler(handler: () => void) {
    const index = this.handlers.findIndex(h => h === handler)
    if(index >= 0) this.handlers.splice(index, 1)
  }

  callHandlers() {
    for(const h of this.handlers) {
      h()
    }
  }
}
