import LocalStorageUtil from "@/assets/lib/utility/LocalStorageUtil";
import StorageValueChangeEvent from "@/assets/lib//utility/StorageValueChangeEvent";

export default class ObjectStorage<T> {
  private readonly key: string
  private _value: T

  event: StorageValueChangeEvent = new StorageValueChangeEvent()


  constructor(key: string, defaultValue: T) {
    this.key = key;
    this._value = LocalStorageUtil.getObject(this.key, defaultValue)
  }

  get value() { return this._value }
  set value(val: T) {
    this.setValueWithoutNotify(val)

    this.event.callHandlers()
  }

  setValueWithoutNotify(val: T) {
    this._value = val
    LocalStorageUtil.setObject(this.key, this._value)
  }

  save() {
    LocalStorageUtil.setObject(this.key, this._value)
  }
}
