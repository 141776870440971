// @ts-ignore
import CryptoJS from "crypto-js";
import Config from "@/config";

export default class CryptoUtil {
  static sha256(msg: string) {
    return CryptoJS.SHA256(msg).toString(CryptoJS.enc.Base64);
  }

  static sha256Hex(msg: string) {
    return CryptoJS.SHA256(msg).toString(CryptoJS.enc.Hex);
  }

  static signature(timeStamp: string) {
    return CryptoJS.HmacSHA256(`${Config.CONFIG.CLIENT_ID}:${Config.CONFIG.CLIENT_KEY}:${timeStamp}`, Config.CONFIG.CLIENT_SECRET).toString();
  }

  static getRandomString(len: number) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let ret = '';
    for (let i = 0; i < len; i++) {
      const index = Math.floor(Math.random() * chars.length);
      ret += chars.charAt(index);
    }
    return ret;
  }
}
