export default class LocalStorageUtil {
  static getBoolean(key: string, defaultValue: boolean) {
    const val = localStorage.getItem(key)
    if(val === null) return defaultValue
    return val === 'true'
  }
  static setBoolean(key: string, val: boolean) {
    localStorage.setItem(key, val ? 'true' : 'false')
  }

  static getNumber(key: string, defaultValue: number) {
    const val = localStorage.getItem(key)
    if(val === null) return defaultValue
    return Number(val)
  }
  static setNumber(key: string, val: number) {
    localStorage.setItem(key, val + '')
  }

  static getObject<T>(key: string, defaultValue: T): T {
    const val = localStorage.getItem(key)
    if(val === null) return defaultValue
    return JSON.parse(val)
  }
  static setObject<T>(key: string, val: T) {
    localStorage.setItem(key, JSON.stringify(val))
  }

  static getString(key: string, defaultValue: string) {
    const val = localStorage.getItem(key)
    if(val === null) return defaultValue
    return val
  }
  static setString(key: string, val: string) {
    localStorage.setItem(key, val)
  }
}
