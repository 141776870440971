export default class Logger {
  private readonly cls: string;

  constructor(cls: string) {
    this.cls = cls;
  }

  debug(...data: any[]): void { console.debug(this.cls, ...data); }
  info(...data: any[]): void { console.info(this.cls, ...data); }
  log(...data: any[]): void { console.log(this.cls, ...data); }
  colorLog(color: string, ...data: any[]): void { console.log('%c' + this.cls, color, ...data); }
  warn(...data: any[]): void { console.warn(this.cls, ...data); }
  error(kind: string, ...data: any[]): any {
    const errorData = {from: this.cls, kind, data: ((data && data.length === 1) ? data[0] : data)};
    console.error(this.cls, kind, ...data);
    return errorData;
  }

  static objToJson(obj: any) {
    if(Array.isArray(obj)) {
      const rr: any = [];
      for(const item of obj) {
        rr.push(this.objToJson(item))
      }
      return rr;
    } else {
      const ret: any = {};
      for(const key in obj) {
        if(typeof obj[key] !== 'function')
          ret[key] = obj[key];
      }
      return ret;
    }
  }

}
